import { Component } from "@angular/core";
import { Address } from "./models/address";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  address: Address = new Address();

  /*
  * Event handler for mapclick in child component: app-map
  * When the user select a valid address in the map, this address is sent to the
  * app-map component for display in the registration form.
  */
  onMapClick(address: Address) {
    console.log('Parent got, ', address);
    this.address = address;
  }
}
