import { Constants } from "./../constants/contstants";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) {}

  // This function makes a post to the server, containing customer information.
  postCustomer(customer) {
    return this.http.post(
      environment.API_ENDPOINT + Constants.FIBER + Constants.REGISTER_CUSTOMER,
      customer
    );
  }
}
