export class Address {
    id: number;
    adromrade: string;
    adrplats: string;
    postnr: string;
    postort: string;
    latitud: number;
    longitud: number;

    public Address() {
        this.id = 0;
        this.adromrade = '';
        this.adrplats = '';
        this.postnr = '';
        this.postort = '';
    }
}
