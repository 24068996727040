import { ConnectionChart } from "./../../models/connection-chart";
import { Component, OnInit } from "@angular/core";
import { ChartService } from "../../services/chart.service";
import { Chart } from "chart.js";

@Component({
  selector: "app-connection-chart",
  templateUrl: "./connection-chart.component.html",
  styleUrls: ["./connection-chart.component.css"]
})
export class ConnectionChartComponent implements OnInit {
  connectionChart: ConnectionChart;

  constructor(private chartService: ChartService) {}

  ngOnInit() {
    // Get connection chart data on init.
    this.chartService.getConnectionChartData().subscribe(
      res => {
        this.connectionChart = res;
        this.createConnectionChart(this.connectionChart);
      },
      err => {}
    );
  }

  /**
   * This function creates a pie chart based on connection data.
   */
  createConnectionChart(connectionChart): void {
    // tslint:disable-next-line:no-unused-expression
    new Chart(document.getElementById("pie-chart"), {
      type: "pie",
      data: {
        labels: ["Anslutna fiber", "Anslutna koax", "Beställda", "Intresserade"],
        datasets: [
          {
            label: "Diagram",
            backgroundColor: [
              "#ABE03C", // anslutna fiber
              "#8E5EA2", // anslutna koax
              "#7AE2FA", // beställda
              "#FCC701", // intresserade
              "#BABFC5"  // ej anslutna
            ],
            data: [
              connectionChart.anslutnaFiber,
              connectionChart.anslutnaKoax,
              connectionChart.bestallda,
              connectionChart.intresserade
            ]
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: "Antalet anslutna hushåll"
        }
      }
    });
  }
}
