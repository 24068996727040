import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Constants } from '../constants/contstants';
import { Address } from '../models/address';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';

@Injectable()
export class MapService {

  constructor(private http: HttpClient) { }

  getAddressFromCoordinates(lon: number, lat: number) {
    return this.http
      .get<Address>(`${environment.API_ENDPOINT}${Constants.ADDRESS}${Constants.GET_ADDRESS_FROM_COORDS}?lon=${lon}&lat=${lat}`);
  }

  getAddressFromText(text: string) {
    const parameter = text === '' ? '' : `?text=${text}`;
    return this.http
      .get<Address[]>(`${environment.API_ENDPOINT}${Constants.ADDRESS}${Constants.GET_ADDRESS_FROM_TEXT}${parameter}`);
  }
}
