import { FiberBlock } from "./../../models/fiber-block";
import { FiberBlockService } from "./../../services/fiber-block.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-fiber-block",
  templateUrl: "./fiber-block.component.html",
  styleUrls: ["./fiber-block.component.css"]
})
export class FiberBlockComponent implements OnInit {
  fiberBlocks: FiberBlock[] = [];

  constructor(private fiberBlockService: FiberBlockService) {}

  ngOnInit() {
    // Get connection chart data on init.
    this.fiberBlockService.getFiberBlockData().subscribe(
      res => {
        this.fiberBlocks = res;
      },
      err => {}
    );
  }
}
