export class Customer {
  // Connection address
  streetNameConnectionAddress: string;
  streetNumberConnectionAddress: string;
  zipCodeConnectionAddress: string;
  cityConnectionAddress: string;

  // Contact address
  streetNameContactAddress: string;
  streetNumberContactAddress: string;
  zipCodeContactAddress: string;
  cityContactAddress: string;

  // Contact information
  name: string;
  phoneNumber: string;
  email: string;
  comment: string;

  // Contact address
  contactAddress: string;
}
