import { NewConnectionChart } from "./../../models/new-connection-chart";
import { Component, OnInit } from "@angular/core";
import { ChartService } from "../../services/chart.service";
import { Chart } from "chart.js";

@Component({
  selector: "app-new-connection-chart",
  templateUrl: "./new-connection-chart.component.html",
  styleUrls: ["./new-connection-chart.component.css"]
})
export class NewConnectionChartComponent implements OnInit {
  newConnectionChart: NewConnectionChart;
  constructor(private chartService: ChartService) { }

  ngOnInit() {
    // Get new connection chart data on init.
    this.chartService.getNewConnectionChartData().subscribe(
      res => {
        this.newConnectionChart = res;
        this.createNewConnectionChart(res);
      },
      err => { }
    );
  }

  /**
   * This function creates a bar chart based on connection data.
   */
  createNewConnectionChart(data): void {
    const date = new Date();
    const datasetYear1 = {
      label: date.getFullYear() - 2,
      backgroundColor: ['#A6D2EB', '#A6D2EB', '#A6D2EB', '#A6D2EB', '#A6D2EB', '#A6D2EB',
        '#A6D2EB', '#A6D2EB', '#A6D2EB', '#A6D2EB', '#A6D2EB', '#A6D2EB'],
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };
    const datasetYear2 = {
      label: date.getFullYear() - 1,
      backgroundColor: ['#3293CB', '#3293CB', '#3293CB', '#3293CB', '#3293CB', '#3293CB',
        '#3293CB', '#3293CB', '#3293CB', '#3293CB', '#3293CB', '#3293CB'],
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };
    const datasetYear3 = {
      label: date.getFullYear(),
      backgroundColor: ['#004E99', '#004E99', '#004E99', '#004E99', '#004E99', '#004E99',
        '#004E99', '#004E99', '#004E99', '#004E99', '#004E99', '#004E99'],
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };

    data.forEach(element => {
      switch (element.month) {
        case 'jan': datasetYear1.data[0] += element.firstYear;
          datasetYear2.data[0] += element.secondYear;
          datasetYear3.data[0] += element.thirdYear;
          break;
        case 'feb': datasetYear1.data[1] += element.firstYear;
          datasetYear2.data[1] += element.secondYear;
          datasetYear3.data[1] += element.thirdYear;
          break;
        case 'mar': datasetYear1.data[2] += element.firstYear;
          datasetYear2.data[2] += element.secondYear;
          datasetYear3.data[2] += element.thirdYear;
          break;
        case 'apr': datasetYear1.data[3] += element.firstYear;
          datasetYear2.data[3] += element.secondYear;
          datasetYear3.data[3] += element.thirdYear;
          break;
        case 'maj': datasetYear1.data[4] += element.firstYear;
          datasetYear2.data[4] += element.secondYear;
          datasetYear3.data[4] += element.thirdYear;
          break;
        case 'jun': datasetYear1.data[5] += element.firstYear;
          datasetYear2.data[5] += element.secondYear;
          datasetYear3.data[5] += element.thirdYear;
          break;
        case 'jul': datasetYear1.data[6] += element.firstYear;
          datasetYear2.data[6] += element.secondYear;
          datasetYear3.data[6] += element.thirdYear;
          break;
        case 'aug': datasetYear1.data[7] += element.firstYear;
          datasetYear2.data[7] += element.secondYear;
          datasetYear3.data[7] += element.thirdYear;
          break;
        case 'sep': datasetYear1.data[8] += element.firstYear;
          datasetYear2.data[8] += element.secondYear;
          datasetYear3.data[8] += element.thirdYear;
          break;
        case 'okt': datasetYear1.data[9] += element.firstYear;
          datasetYear2.data[9] += element.secondYear;
          datasetYear3.data[9] += element.thirdYear;
          break;
        case 'nov': datasetYear1.data[10] += element.firstYear;
          datasetYear2.data[10] += element.secondYear;
          datasetYear3.data[10] += element.thirdYear;
          break;
        case 'dec': datasetYear1.data[11] += element.firstYear;
          datasetYear2.data[11] += element.secondYear;
          datasetYear3.data[11] += element.thirdYear;
          break;
      }
    });
    // tslint:disable-next-line:no-unused-expression
    new Chart(document.getElementById("bar-chart"), {
      type: "bar",
      data: {
        labels: [
          "jan",
          "feb",
          "mar",
          "apr",
          "maj",
          "jun",
          "jul",
          "aug",
          "sep",
          "okt",
          "nov",
          "dec"
        ],
        datasets: [
          datasetYear1,
          datasetYear2,
          datasetYear3
        ]
      },
      options: {
        legend: { display: true },
        title: {
          display: true,
          text: "Diagram över antalet nyanslutningar"
        },
        scales: {
          yAxes: [{
              ticks: {
                  stepSize: 5
              }
          }]
      }
      }
    });
  }
}
