import { ConnectionChart } from "./../models/connection-chart";
import { Constants } from "./../constants/contstants";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";
import { NewConnectionChart } from "../models/new-connection-chart";
import { environment } from '../../environments/environment';

@Injectable()
export class ChartService {
  constructor(private http: HttpClient) {}

  /**
   * This function returns connection chart data.
   */
  getConnectionChartData() {
    return this.http
      .get(
        environment.API_ENDPOINT +
          Constants.FIBER +
          Constants.GET_CONNECTION_CHART
      )
      .map((res: ConnectionChart) => {
        return res;
      });
  }

  /**
   * This function returns new connection chart data.
   */
  getNewConnectionChartData() {
    return this.http
      .get(
        environment.API_ENDPOINT +
          Constants.FIBER +
          Constants.GET_NEW_CONNECTION_CHART
      )
      .map((res: NewConnectionChart) => {
        return res;
      });
  }
}
