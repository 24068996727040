export const Constants = {
  // Production Link
  // API_ENDPOINT : "https://oktv-admin.optiway.se/api/",
  // Test Link
  //API_ENDPOINT : "https://oktv-admin-test.optiway.se/api/",
  // Local Development
  //API_ENDPOINT: "http://localhost:55146/api/",
  FIBER: "fiber2/",
  ADDRESS: "searchAddress/",
  GET_CONNECTION_CHART: "getConnectionChart/",
  GET_NEW_CONNECTION_CHART: "getnewconnectionchart/",
  REGISTER_CUSTOMER: "register/",
  GET_FIBER_BLOCK_DATA: "getfiberblockdata/",
  GET_ADDRESS_FROM_COORDS: "getAddressFromCoords",
  GET_ADDRESS_FROM_TEXT: "getAddressFromText",
  CAPABILITIES: "map/getcapabilities",
  WMTS_PROXY: "map/getortotile",
  WMS_PROXY: "map/gettiles",
  // Contact address radio buttons
  CONTACT_ADDRESS_RADIO_BUTTONS: ["Ja", "Nej"]
};
