import { Constants } from "./../../constants/contstants";
import { Component, OnInit, Input } from "@angular/core";
import { Customer } from "../../models/customer";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { CustomerService } from "../../services/customer.service";
import { Address } from "../../models/address";
import { environment } from '../../../environments/environment';

import * as $ from "jquery";

@Component({
  selector: "app-interest-inquiry",
  templateUrl: "./interest-inquiry.component.html",
  styleUrls: ["./interest-inquiry.component.css"]
})
export class InterestInquiryComponent implements OnInit {
  loading = false;
  result = { success: false, title: '', message: '' };
  customer: Customer = new Customer();
  constants = Constants;
  inputFormConnectionAddress: FormGroup = new FormGroup({});
  inputFormContactAddress: FormGroup = new FormGroup({});
  inputForm: FormGroup = new FormGroup({});
  envName = environment.name;
  formToggled = false;

  // Receive address from click in the map component via the parent controller and populate the form
  @Input()
  set addressFromMapClick(address: Address) {
    this.customer.streetNameConnectionAddress = address.adromrade;
    this.customer.streetNumberConnectionAddress = address.adrplats;
    this.customer.zipCodeConnectionAddress = address.postnr;
    this.customer.cityConnectionAddress = address.postort;

    if ((address.adromrade && address.adromrade !== '') && this.formToggled == false) {
      this.toggleForm();
      this.formToggled = true;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    // Set default radio button value.
    if (
      this.customer.contactAddress === undefined ||
      this.customer.contactAddress === null
    ) {
      this.customer.contactAddress = Constants.CONTACT_ADDRESS_RADIO_BUTTONS[0];
    } else {
      this.contactAddressValidation();
    }

    // 'Connection address' validation.
    this.inputFormConnectionAddress = this.formBuilder.group({
      streetNameConnectionAddress: [null, Validators.required],
      streetNumberConnectionAddress: [null, Validators.required],
      zipCodeConnectionAddress: [null, Validators.required],
      cityConnectionAddress: [null, Validators.required]
    });

    // 'Contact information' validation.
    this.inputForm = this.formBuilder.group({
      name: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
    });

    $(document).ready(function () {
      $('#interestInquiry').hide();
    });
  }

  toggleForm(): void {
    $('#interestInquiry').slideToggle();
    this.formToggled = !this.formToggled;
  }
  /**
   * Function that validates the contact address.
   */
  contactAddressValidation(): void {
    if (
      this.customer.contactAddress ===
      Constants.CONTACT_ADDRESS_RADIO_BUTTONS[1]
    ) {
      // 'Contact address' validation.
      this.inputFormContactAddress = this.formBuilder.group({
        streetNameContactAddress: [null, Validators.required],
        streetNumberContactAddress: [null, Validators.required],
        zipCodeContactAddress: [null, Validators.required],
        cityContactAddress: [null, Validators.required]
      });
    } else {
      this.inputFormContactAddress.reset();
      this.inputFormContactAddress.disable();
    }
  }

  /**
   * This function calls a service that makes a post to backend, containing customer information.
   */
  postCustomer(): void {
    this.loading = true;

    this.customerService
      .postCustomer(this.customer)
      .subscribe(res => {
        this.result = {
          success: true,
          title: 'Tack för din intresseanmälan!',
          message: `Markören på din adress kommer växla färg först efter att vi kontrollerat vilken möjlighet till fiberanslutning ni har.
                    Ni kommer då att få ett mail ifrån oss med information om er fiberanslutning.
                    Till dess, berätta gärna för dina grannar om möjligheten till fiberanslutning
                    så att även de skickar in intresseanmälan.`
        };
        this.loading = false;
      }, err => {
        this.result = {
          success: false,
          title: 'Något gick fel.',
          message: `Försök igen. Går det ej att registrera via formuläret, skicka in din intresseanmälan via e-post.`
        };
        this.loading = false;
      });
  }
}
