import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import OlMap from 'ol/map';
import OlOverlay from 'ol/overlay';
import { environment } from '../../environments/environment';

export interface IMapLocationRequest {
  x: number;
  y: number;
  tracker: IMapTracker,
  getEnqueued: any;
  overlay: OlOverlay
}

export interface IMapLocationResult {
  adress: string;
  x: number;
  y: number;
}

export interface IMapTracker {
  map: OlMap;
}

@Injectable()
export class MapInteractionService {

  private busy: boolean[] = [];
  private url = `${environment.API_ENDPOINT}` + 'map/hovering';

  constructor(private http: HttpClient) { }
  
  registerMap(tracker: IMapTracker) {
    const el = document.createElement('div');
    el.className = 'location-tip';

    const tip = new OlOverlay({ element: el, offset: [0, -15], positioning: 'bottom-center' });
    tracker.map.addOverlay(tip);
    this.busy.push(false);

    const index = this.busy.length - 1;
    let enqueued = null;

    tracker.map.on('pointermove', (evt: any) => {
      this.updateLocation(index, {
        x: evt.coordinate[0],
        y: evt.coordinate[1],
        tracker: tracker,
        getEnqueued: () => enqueued,
        overlay: tip
      });
    }, this);

    document.body.addEventListener('mouseout', () => tip.setPosition([-10000000, -10000000]));
  }

  private async update(index: number, req: IMapLocationRequest) {
    this.busy[index] = true;
    const sub = req;
    const newLocation = await this.http.get<IMapLocationResult[]>(`${this.url}?x=${sub.x}&y=${sub.y}`).toPromise();
    this.busy[index] = sub === req.getEnqueued();

    if (newLocation && newLocation.length > 0) {
      req.overlay.setPosition([newLocation[0].x, newLocation[0].y]);
      req.overlay.element.firstChild.innerHTML = newLocation[0].adress;
    }
    if (this.busy[index]) {
      await this.update(index, req);
    }
  }

  public async updateLocation(index: number, req: IMapLocationRequest) {
    if (!this.busy[index]) {
      await this.update(index, req);
    }
  }
}
