import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import OlMap from 'ol/map';
import OlTileWMS from 'ol/source/tilewms';
import OlTileWMTS from 'ol/source/wmts';
import WMTSCapabilities from 'ol/format/wmtscapabilities';
import OlVectorSource from 'ol/source/vector';
import OlTileLayer from 'ol/layer/tile';
import OlVectorLayer from 'ol/layer/vector';
import OlView from 'ol/view';
import OlProj from 'ol/proj';
import OlFeature from 'ol/feature';
import OlPoint from 'ol/geom/point';
import OlControl from 'ol/control/control';
import OlStyleIcon from 'ol/style/icon';
import OlStyle from 'ol/style/style';
import OlCircle from 'ol/style/circle';
import OlFill from 'ol/style/fill';
import Projection from 'ol/proj/projection';

import * as proj4 from 'proj4/dist/proj4';
import { MapService } from '../../services/map.service';
import { Address } from '../../models/address';
import { Observable } from 'rxjs/Observable';
import { FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Constants } from "../../constants/contstants";

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { MapInteractionService } from '../../services/map-interaction.service';

proj4.defs("EPSG:3006", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
OlProj.setProj4(proj4);

/* 
  These projection and orientation settings are needed when getting the tiles from the new WMTS server.
  Without e.g. defining the axisOrientation the map tiles won't work
*/
var projection = new Projection({
  code: 'EPSG:3006',
  units: 'm',
  // Redefine the extent of 3006 to same extent as the GWC grid we want to use (see map-layers)
  extent: [181896.32913603852, 6091282.433471196, 1086312.9422175875, 7689478.305598114],
  axisOrientation: 'neu'
});
OlProj.addProjection(projection);

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  providers: [MapService]
})
export class MapComponent implements OnInit {
  capabilities: any = null;
  @Output() addressFromMapClick = new EventEmitter<Address>();
  map: OlMap = null;
  pointLayer: OlVectorLayer;
  view: OlView;
  config = {
	  extent: [452325.1424, 6208729.0112, 485289.8856, 6257630.0090],
    url: environment.API_ENDPOINT + Constants.WMS_PROXY,
    projection: 'EPSG:3006'
  };
  addresses: Observable<Address[]>;
  searchField: FormControl = new FormControl();

  constructor(private mapService: MapService, private http: HttpClient, private mapInteractionService: MapInteractionService) { }

  // Return the WMTS capabilites from the API.
  public getCapabilities(): Observable<any> {
    const headers = new HttpHeaders({ 'Accept': 'application/xml' });

    return this.http
      .get(`${environment.API_ENDPOINT}${Constants.CAPABILITIES}`, { headers: headers, responseType: 'text' });
  }

  ngOnInit() {
    
      this.getCapabilities().subscribe(capabilities => {
        this.capabilities = capabilities;

        const replace = `${environment.API_ENDPOINT}${Constants.WMTS_PROXY}`;
        this.capabilities = this.capabilities.replace(
          new RegExp('https://gis01.optiwaygis.com/geoserver/gwc/service/wmts', 'g'),
          replace
        );
        // Create the map when the capabilities are fetched and correct
        this.map = new OlMap({
          target: 'map',
          layers: [
            new OlTileLayer({
              extent: this.config.extent,
              source: new OlTileWMTS(
                OlTileWMTS.optionsFromCapabilities(
                  new WMTSCapabilities().read(this.capabilities),
                  {
                    layer: 'owg:cir_oktv_fastighetskarta',
                    matrixSet: 'grid_gis_online'
                  }
                )
              )
            }),
            new OlTileLayer({
              extent: this.config.extent,
              source: new OlTileWMS({
                url: this.config.url,
                params: { 'LAYERS': 'owg:cir_oktv_anslutna_status', 'VERSION': '1.1.1', 'STYLES': 'cir_oktv_anslutna_status' },
                projection: this.config.projection
              })
            }),
            this.pointLayer
          ],
          view: this.view,
          controls: [
            new OlControl({
              element: this.getLogotype()
            }),
            new OlControl({
              element: this.getLMText()
            })
          ]
        });
        // Click handler to search for address
        this.map.on('click', (e) => {
          this.getAddress(e.coordinate);
        });

        this.mapInteractionService.registerMap({ map: this.map });
      });
    
    this.pointLayer = new OlVectorLayer({
      source: new OlVectorSource({
        features: []
      }),
      style: new OlStyle({
        image: new OlStyleIcon({
          src: '../../assets/map-marker-blue-64.png',
          anchorOrigin: 'bottom-left',
          anchor: [0.5, 0.0]
        })
      })
    });

    this.view = new OlView({
      center: [470937.00394213997, 6232845.33590096],
      projection: 'EPSG:3006',
      zoom: 12
    });

    // Get addresses from search input field
    this.addresses = this.searchField.valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .switchMap(term => this.mapService.getAddressFromText(term));
  }

  // Pan the map to the specified coordinates
  panMapToPosition(coordinate: number[], zoom: number): void {
    this.pointLayer.getSource().clear();
    this.pointLayer.getSource().addFeature(new OlFeature({
      geometry: new OlPoint(coordinate)
    }));
    const setToZoom = zoom === null ? this.view.getZoom() : zoom;
    this.view.animate({
      zoom: setToZoom,
      center: coordinate,
      duration: 400
    });
  }

  // Create reference to Lantmäteriet
  getLMText(): HTMLDivElement {
    const lm = document.createElement('span');
    lm.innerHTML = 'Lantmäteriet	&copy;';

    const logoDiv = document.createElement('div');
    logoDiv.style.position = 'absolute';
    logoDiv.style.left = '2px';
    logoDiv.style.bottom = '2px';
    logoDiv.style.fontSize = '0.75em';
    logoDiv.appendChild(lm);

    return logoDiv;
  }

  // Create OptiWay logo
  getLogotype(): HTMLDivElement {
    const logo = document.createElement('img');
    logo.setAttribute('src', '../../../assets/visma_logo_150by53.png');
    logo.setAttribute('width', '53px');
    const logoDiv = document.createElement('div');
    logoDiv.style.position = 'absolute';
    logoDiv.style.right = '2px';
    logoDiv.style.bottom = '2px';
    logoDiv.appendChild(logo);

    return logoDiv;
  }

  /*
   Get the address for the selected coordinates in the map.
   A valid address is sent to the app-map component for display in the registration form.
  */
  getAddress(coordinate) {
    this.mapService.getAddressFromCoordinates(coordinate[0], coordinate[1]).subscribe(address => {
      if (address !== null) {
        this.panMapToPosition([address[0].latitud, address[0].longitud], null);
        this.addressFromMapClick.emit(address[0]);
        this.searchField.reset('');
      }
    },
    error => {
      console.log(error);
    });
  }

  // Selected address from search result
  selectAddress(address: Address): void {
    this.panMapToPosition([address.latitud, address.longitud], 18);
    this.addressFromMapClick.emit(address);
    this.searchField.reset('');
  }

  zoomIn(): void {
    this.view.animate({ zoom: this.view.getZoom() + 1, duration: 300 });
  }

  zoomOut(): void {
    this.view.animate({ zoom: this.view.getZoom() - 1, duration: 300 });
  }
}
