import { FiberBlock } from "./../models/fiber-block";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Constants } from "../constants/contstants";
import { environment } from '../../environments/environment';

@Injectable()
export class FiberBlockService {
  constructor(private http: HttpClient) {}

  /**
   * This function returns fiber block data.
   */
  getFiberBlockData() {
    return this.http
      .get(
        environment.API_ENDPOINT +
          Constants.FIBER +
          Constants.GET_FIBER_BLOCK_DATA
      )
      .map((res: Array<FiberBlock>) => {
        return res;
      });
  }
}
