import { FiberBlockService } from "./services/fiber-block.service";
import { ChartService } from "./services/chart.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { InterestInquiryComponent } from "./components/interest-inquiry/interest-inquiry.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CustomerService } from "./services/customer.service";
import { FiberBlockComponent } from "./components/fiber-block/fiber-block.component";
import { ContactInformationComponent } from "./components/contact-information/contact-information.component";
import { MapComponent } from "./components/map/map.component";
import { NewConnectionChartComponent } from "./components/new-connection-chart/new-connection-chart.component";
import { ConnectionChartComponent } from "./components/connection-chart/connection-chart.component";
import { MapInteractionService } from "./services/map-interaction.service";

@NgModule({
  declarations: [
    AppComponent,
    InterestInquiryComponent,
    FiberBlockComponent,
    ContactInformationComponent,
    MapComponent,
    NewConnectionChartComponent,
    ConnectionChartComponent
  ],
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  providers: [CustomerService, ChartService, FiberBlockService, MapInteractionService],
  bootstrap: [AppComponent]
})
export class AppModule {}
